

.pages{
  font-family: RobotoSlab-Light !important;
}

.pages .primaryTitle{
  font-size: 4.2rem;
  position: relative;
  font-weight: 800;
  margin-bottom: 0;
  margin: 0 auto;
  font-family: Nuno700;
  text-align: center;
}

.pages .secondaryTitle{
  font-size: 2.2rem;
  position: relative;
  font-weight: 800;
  margin-bottom: 0;
  margin: 0 auto;
  font-family: Nuno700;
}

.pages .card{
  display: grid !important;
}

.pages .primaryTitle{
  text-transform:none;
  margin-bottom: 20px;
}

.pages .card h2{
    content: "";
    margin-bottom: 3.5rem;
    display: block;
    height: 0.2rem;
    background-color: #3c7ec8;
    line-height: 4rem;

}

.pages .card p{ 
  line-height: 2rem;
}

.pages .reverse h2{
  background-color: #fff !important;
}

.container.pages {
  background: #fafafa;
}

.verticalCardImage img {
  -webkit-transition: width 2s;
  top: 0;
  width: 100%;
  height: auto;
  opacity: 0.9;
  object-fit: cover;
}

.verticalCardImage:hover {
  cursor: pointer;
}

.verticalCardImage:hover img {
  transition: all 1000ms;
  width: 70%;
  opacity: 0.4;
}

.verticalCardImage:hover .textBox {
  transition: all 1000ms;
  color: #d5d268;
}

.verticalCardImage {
  position: relative;
  height: 500px;
  background-color: black;
  display: flex;
  margin: 3px;
}

.verticalCardImage .textBox {
  padding: 10px;
  position: absolute;
  display: block;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  bottom: 0;
  width: 100%;
}

.verticalCardImage .textBox h2,
.verticalCardImage .textBox p {
  width: 100%;
  flex-direction: row;
}

.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  border: 1px solid gray;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
}

.modal .header {
  background-color: black;
  width: 100%;
  height: 5rem;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal .header p {
  width: 100%;
  margin-left: 20px;
}

.modal span {
  color: #fff;
  font-size: 3rem;
  padding: 10px;
  float: right;
  cursor: pointer;
}

.modal .wrapper {
  height: 90%;
  width: 100%;
  border: 1px solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal img {
  max-width: 800px;
  max-height: 90%;
}

.modal .btn-prev,
.modal .btn-next {
  width: 60px;
  height: 60px;
  background-color: #fff;
  color: #000;
  font-size: 42px;
  cursor: pointer;
  vertical-align: middle;
  display: inline-table;
}

.modal .btn-prev:hover,
.modal .btn-next:hover {
  background-color: #464646;
  color: #fff;
}

.modal .btn-prev {
  position: absolute;
  top: 45%;
  left: 0;
}

.modal .btn-next {
  position: absolute;
  top: 45%;
  right: 0;
}

@media screen and (max-width: 890px) {
  .pages .card h2 {
    margin-bottom: 1rem;
    height: 0rem;
    background-color: transparent;
    line-height: 2rem;
    border-bottom: 2px solid #3c7cc8;
    display: inline-table;
  }
  .pages .reverse h2{
    background-color: transparent !important;
  }
}

.sendForm{
  margin-top: 1rem;
  text-align: center;
  color: white;
  font-family: "Open Sans","Helvetica Neue","Helvetica","Roboto","Arial",sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background: #007ec8;
  padding: 10px;
  border: 1px solid #fff;
}

.disabledBtn {
  margin-top: 1rem;
  text-align: center;
  color: #8d8d8d !important;
  font-family: "Open Sans","Helvetica Neue","Helvetica","Roboto","Arial",sans-serif;
  font-weight: 600;
  cursor: auto !important;
  text-decoration: none;
  background: #ccc !important;
  padding: 10px;
  border: 1px solid #fff;
}