.navbar {
  background: #fff;
  height: 80px;
  /* display: flex; */
  /* justify-content: center; */
  align-items: center;
  font-size: 0.8rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  height: 80px;
  width: 100%;
  /* max-width: 1500px; */
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: flex;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  /* width: 50vw; */
  margin-right: 2rem;
  justify-content: end;
}

.menu{
  height: 100vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  background: #242222;
  top: 80px;
}

.nav-menu2{
  justify-content: end;
  width: 100%;
}

.nav-item {
  height: auto;
}

.nav-links {
  color: #5c5c5c;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #007ec8;
  color: #007ec8;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #000;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

.nav-item .submenu{
  display: none;
}

.nav-item:hover .submenu{
  display: inline;
} 

.nav-item a{
  cursor: pointer;
  color: #000;
  text-decoration: none;
}

ul.submenu {
  background: #fff;
  list-style: none;
  text-align: left;
  position: absolute;
  margin-left: -50px;
}

.submenu li {
  padding-right: 100px;
  width: 100%;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  cursor: pointer;
}

.submenu li:hover .sub-submenu {
  display: block;
}

ul.sub-submenu {
  position: absolute;
  background: #ffffff;
  margin-left: 96%;
  list-style: none;
  top: 0;
  border-left: 1px solid #ccc;
  display: none;
}

.sub-submenu{
  width: 100%;
}

.sub-submenu li {
  padding-right: 10px;
}

.sub-submenu li a{
  color: #000 !important;
}

.submenu li:hover {
  background: #dfecf4;
}

@media screen and (max-width: 1080px) {
}
@media screen and (max-width: 960px) {
  .listMargin{
    margin-left: 0px;
  }
  .submenu li:hover {
    background: transparent;
    border-bottom: 1px solid #007ec8;
  }

  ul.submenu {
    position: inherit !important;
    padding-left: 0px !important;
    margin-left: auto !important;
  }

  .submenu li {
    overflow-wrap: break-word;
    color: #000;
    text-align: center;
    display: flow-root;
    padding: 15px 0px;
    z-index: 99999;
  }

  .menu {
    padding-bottom: 100px;
  }

  .nav-item a{
    color: #fff;
  }

  .submenu a {
    color: #000;
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    display: block;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: start;

  }

  ul.sub-submenu{
    position: inherit;
    margin-left: 0%;
  }

  .nav-menu.active2{
    justify-content: end !important;
    background: none !important;
    display: contents;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    top: 0px;
    position: initial;
    display: contents;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: grid;
    height: 80px;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 25%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #000;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .nav-item .submenu{
    display: none;
  }

  .nav-item:hover .submenu{
    display: block;

  } 

  .submenu li {
    overflow-wrap: break-word;
  }
}