.navbar{
    transform: translateY(-300px);
    animation: slideDown 3s ease-in-out forwards 3s;
  }
  @keyframes slideDown {
    0% {
      transform: translateY(-300px);
    }
    100% {
      transform: translateY(0px);
    }
  }