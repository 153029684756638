body{
  background: #fafafa;
  font-family: Nuno, "PT Sans", sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: Nuno;
  src: url(assets/fonts/NunitoSans-Regular.ttf);
}

@font-face {
  font-family: Nuno700;
  src: url(assets/fonts/NunitoSans-Bold.ttf);
}

@font-face {
  font-family: RobotoSlab;
  src: url(assets/fonts/RobotoSlab.ttf);
}

@font-face {
  font-family: RobotoSlab-Light;
  src: url(assets/fonts/RobotoSlab-Light.ttf);
}

p { line-height: 2rem;}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.card {
  background-color: #fff;
  color: #000;
  padding: 15px;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
  margin: 10px;
  border-radius: 2px;
  font-size: 1rem;
  display: -webkit-flex;
}

.card-no-border {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 70%) !important;
}

.card:hover {
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 50%);
  transition: all 500ms;
  /* background: #091554; */
  /* color: #fff; */
}

.card ul{
  padding: 40px;
}

.card ul li{
  line-height: 2rem;
}

.layout-transparent{ 
  background-color: transparent;
  color: #fff;
  padding: 15px;
  margin: 10px;
  font-size: 1rem;
}

.layout-transparent a{
  cursor: pointer;
  opacity: 0;
  position: relative;
  font-weight: 600;
  display: inline-block;
  margin-bottom: 2rem;
  color: #fff;
}

.layout-transparent h4 {opacity: 1; width: 100%; text-align: center;}

.layout-transparent a:hover::before{
  width: 100%;
}
.layout-transparent a:hover::after{
  margin-left: 100%;
}


.layout-transparent a:before{
  width: 2rem;
  background: #007ec8;
  z-index: 2;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: " ";
  position: absolute;
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
}


.layout-transparent a:after{
  right: 0;
  background: #000;
  margin-left: 0;
  z-index: 1;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  margin-left: 2.9rem;
  transition: margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: " ";
  position: absolute;
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
}

.layout-transparent:hover {
  background-color: #023c5d;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 50%);
  border-radius: 2px;
  transition: all 1000ms;
  transform: translateY(-10px);
}

.layout-transparent:hover a {
  opacity: 1;
  transition: all 1500ms;
}

.layout-transparent:hover h4 {
  opacity: 0;
}

.container {
  margin: 0 auto;
  position: relative;
  padding: 25px;
  /* margin-left: 100px; */
}

.container img{
  margin: 25px;
}

.row {
  display: flex;
  width: 100%;
}

.bg-section{
  padding: 100px;
}

.row:before,
.row:after {
  content: " ";
  display: table;
}

.col {
  float: left;
  margin: 10px;
  min-height: 100px;
}

.withoutmargin {
  margin: 0 auto;
}

.col-2 {
  width: 20%;
  margin: 0 auto;
}

.col-3 {
  width: 33%;
  margin: 0 auto;
}

.col-4 {
  width: 25%;
  margin: 0 auto;
}

.col-5 {
  width: 50%;
  margin: 0 auto;
}

.col-4-reverse {
  width: 75%;
  margin: 0 auto;
}

.col-40-60 {
  width: 40%;
  margin: 0 auto;
}
.col-60-40 {
  width: 60%;
  margin: 0 auto;
}

.col-30-70 {
  width: 30%;
  margin: 0 auto;
}
.col-30-70 img{
  width: -webkit-fill-available;
  max-width: 100%;
  padding-top: 3rem;
}
.col-70-30 {
  width: 70%;
  margin: 0 auto;
}

.col-3-reverse {
  width: 66.6%;
  margin: 0 auto;
}

.col-2-reverse {
  width: 80%;
  margin: 0 auto;
}

.col-10 {
  width: 100%;
  margin: 0 auto;
}

.marginTop20 {
  margin-top: 20px;
}

.marginBottom20 {
  margin-bottom: 20px;
}

.primaryTitle {
  font-size: 40px;
  line-height: 64px;
  text-transform: uppercase;
  float: left;
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
  color: #000;
  font-weight: 100;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.noBullets {
  list-style: none;
}

.list {
  width: 100%;
}

.list li {
  border: 1px solid #1a1818;
  width: 100%;
  display: flex;
  background: #fff;
}

.list span {
  padding: 5px;
  min-height: 1rem;
}

.bgBlack {
  background: #1a1818;
  color: #fff;
}

.ra {
  color: #2bb2ff;
}
.mi {
  color: #ff5b5c;
}
.co {
  color: #d9d9d9;
}

.col-height-30{
  min-height: 30rem;
}

.text-center{ text-align: center !important;}
.w-100{ width: 100%;}
.p-30{padding: 30px;}
.p-45{padding: 45px;}

.wrapSection {
  padding: 0 15% 0 15%;
  text-align: center;
}

.flow-root .card{
  padding: 0px;
}
.flow-root{
  padding: 30px;
  padding-left: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 50px 50px;
  grid-template-areas:
      "box-1 box-3 box-2 box-6"
      "box-1 box-5 box-4 box-4";
  margin-top: -80px;
  margin-bottom: -250px;
}
.box-1 {
  grid-area: box-1;
  z-index: 1;
  margin-top: 90px;
}
.box-2 {
  margin-top: 40px;
  z-index: 1;
  grid-area: box-2;
}
.box-3 {
  grid-area: box-3;
  z-index: 1;
  margin-top: 20px;
}
.box-4 {
  grid-area: box-4;
  z-index: 1;
}
.box-5 {
  grid-area: box-5;
  z-index: 1;
  margin-top: -140px;
}
.box-6 {
  grid-area: box-6;
  z-index: 1;
}

.flow-root .card{
  display: inline-block;
}

.flow-root .card h3{
  color: #3c7ec8;
}

.box-4 p{
  margin-left: 20px;
}

/* .flow-root .container{
  display: flow-root;
} */

/* .flow-root .grid1{
  grid-column: 1;
  grid-row: 1 / 5;
  width: 100%;
}
.flow-root .grid2{
  grid-row: 1;
  width: 100%;
}
.flow-root .grid3{
  grid-row: 1;
  width: 100%;
}
.flow-root .grid4{
  grid-column: 2 / 4;
  grid-row: 2;
  width: 100%;
  display: inherit;
}
.flow-root .grid4-2{
  grid-column: 2 / 4;
  grid-row: 1;
  width: 100%;
  display: inline-table;
} */

/* .grid4 h2.title-tag,.grid4-2 h2.title-tag {
  position: relative;
  display: table;
  margin-left: -20px;
  margin-right: 10px;
} */

.no-padding{ padding: 0px !important;}

.float-left{ float: left;}

.card .title-tag{
    z-index: 5;
    position: absolute;
    top: 15px;
    left: -10px;
    box-shadow: 7px 7px 46.41px 4.59px rgb(192 191 192 / 15%);
    padding: 0.8rem 3.5rem;
    display: inline-block;
    background-color: #3d9c01;
    color: #fff;
    margin-left: -20px;
    margin-right: 10px;
}

.card-horizontal p{
    float: left;
    width: 45%;
}

.card-horizontal img{
  float: left;
  width: 50% !important;
}

.card img{
  width: 100%;
  margin: 0 auto !important;
}

.grid{
  display: grid !important;
}

.grid img{
  width: 100%;
}

.algin-content-left{
  float:left;
}

.algin-content-right{
  float:left;
}
.justify{
  text-align: justify;
}

.listMargin{
  margin-left: 50px;
}
li.sublist {
  border: 1px dotted #3c7ec8;
  margin: 10px 0px;
  padding: 15px;
}
.listMargin b{
  color: #3c7ec8;
}

.marginBottom20{ margin-bottom: 20px}

.noStyle{ list-style: none;}
.imageSection{ 
  height: 350px;
  background-image: url("assets/bg_inicio.jpg");
  background-position: center;
  background-size: cover;
}
.imageSection h1{
  font-size: 3rem;
  color: #fff;
}

.vertical-center {
  height: 350px;
  line-height: 350px;
  text-align: center;
}

.vertical-center h1{
  text-shadow: 1px 1px 2px black;
}

.vertical-center p, .vertical-center h1{
  display: inline-block;
  vertical-align: middle;
  line-height: normal;
}

@media screen and (max-width: 1520px) {
  .row {
    display: block;
  }
  .container {
    margin-left: 0px;
  }
  
  .col-3{
    width: 33%;
    margin: 0 auto;
  }
  .col-3-reverse{
    width: 66%;
    margin: 0 auto;
  }
  .col-2{
    width: 20%;
    margin: 0 auto;
  }
  .col-2-reverse {
    width: 80%;
    margin: 0 auto;
  }
  .col-4{
    width: 40%;
    margin: 0 auto;
  }
  .col-4-reverse{
    width: 60%;
    margin: 0 auto;
  }
  /* .col-10, .col-40-60,.col-60-40,.col-70-30,.col-30-70 {
    width: 100%;
    margin: 0 auto;
  } */

  .grid1 img {
    height: 250px;
    object-fit: cover;
    object-position: 0px -215px;
}

  .flow-root{
    padding: 30px;
    padding-left: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 50px 50px;
    grid-template-areas:
        "box-1 box-2 box-6"
        "box-3 box-5 box-4";
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .card-horizontal p{
    float: none;
    width: 100%;
}

  .card-horizontal img{
    float:none;
    width: 100% !important;
  }

  .box-1 {
    grid-area: box-1;
    z-index: 1;
    margin-top: 0px;
  }
  .box-2 {
    margin-top: 0px;
    z-index: 1;
    grid-area: box-2;
  }
  .box-3 {
    grid-area: box-3;
    z-index: 1;
    margin-top: 0px;
  }
  .box-4 {
    grid-area: box-4;
    z-index: 1;
  }
  .box-5 {
    grid-area: box-5;
    z-index: 1;
    margin-top: 0px;
  }
  .box-6 {
    grid-area: box-6;
    z-index: 1;
  }
  
}
@media screen and (max-width: 1250px) {
  .flow-root{
    padding: 30px;
    padding-left: 60px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 50px 50px;
    grid-template-areas:
        "box-1 box-2"
        "box-3 box-4"
        "box-5 box-6";
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .card-horizontal p{
    float: none;
    width: 100%;
}

  .card-horizontal img{
    float:none;
    width: 100% !important;
  }

  .box-1 {
    grid-area: box-1;
    z-index: 1;
    margin-top: 0px;
  }
  .box-2 {
    margin-top: 0px;
    z-index: 1;
    grid-area: box-2;
  }
  .box-3 {
    grid-area: box-3;
    z-index: 1;
    margin-top: 0px;
  }
  .box-4 {
    grid-area: box-4;
    z-index: 1;
  }
  .box-5 {
    grid-area: box-5;
    z-index: 1;
    margin-top: 0px;
  }
  .box-6 {
    grid-area: box-6;
    z-index: 1;
  }
}


@media screen and (max-width: 600px) {
  .col-2,
  .col-2-reverse,
  .col-3,
  .col-3-reverse,
  .col-4,
  .col-4-reverse,
  .col-5,
  .col-10 {
    width: 100% !important;
    margin: 0 auto;
  }
  .flow-root{
    display: inline-block !important;
    padding: 0px;
    padding-left: 0px;
  }
  .flow-root .grid1{
    width: 100%;
  }
  .flow-root .grid2{
    width: 100%;
  }
  .flow-root .grid3{
    width: 100%;
  }
  .flow-root .grid4{
    width: 100%;
  }
  .card-horizontal img{
    width: 100% !important;
    float: none !important;
  }
  
  /* .grid4 h2.title-tag {
    position: relative;
    display: table;
    margin-left: -20px;
  } */
}

.download_home{
  padding: 10px;
}

.sliderContent{
  transform: translateY(-300px);
  animation: slideUp 2s ease-in-out forwards 2s;
  opacity: 0;
}


.slide h1 {
    top: 0;
    position: absolute;
    background: rgba(0,0,0,.5);
    color: #fff;
    padding: 14% 18% 21% 17%;
}

.sliderContent:before {
  height: 100%;
  width: 10px;
  background: #ff0000;
  position: absolute;
  z-index: 999;
}

.contentScroll{
  /* visibility: hidden; */
  /* opacity: 0; */
  /* transition: visibility 0s, opacity 0.5s linear; */
  transform: translateY(300px);
  opacity: 0;
  animation: showContent 2.8s ease-in-out forwards 2.8s;
}

@keyframes slideUp {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes slideDownLines {
  0% {
    height: 1%;
  }
  100% {
    height: 100%;
    opacity: 0;
  }
}
@keyframes showContent {
  0% {
    transform: translateY(300px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.lines{
  height: 1%;
  transform:scaleY(0,100);
  background-color: #007ec8;
  width: 1px;
  position: absolute;
  margin-left: 25%;
  animation: slideDownLines 1.8s ease-in-out forwards 1.8s;
  z-index: -1;
}
.lines2{
  margin-left: 50%;
  animation: slideDownLines 1.4s ease-in-out forwards 1.4s;
}
.lines3{
  margin-left: 75%;
  animation: slideDownLines 1.6s ease-in-out forwards 1.6s;
}

@media screen and (max-width: 890px) {
  .primaryTitle{
    font-size: 2.5rem !important;
    line-height: 2.5rem;
  }
  .wrapSection{
    text-align: justify !important;
    padding: 0 ;
  }
  .container img{
    margin: 20px 0px;
  }
  .col-70-30 {
    width: 100%;
    margin: 0 auto;
  }
  .col-30-70 {
    width: 100%;
    margin: 0 auto;
  }
  .col-60-40 {
    width: 100%;
    margin: 0 auto;
  }
  .col-40-60 {
    width: 100%;
    margin: 0 auto;
  }
  .listMargin{
    display: inherit !important;
    margin-left: 40px !important;
  }
  .row.marginTop20.bg-section {
    display: flex !important;
    background: rgba(0, 0, 0, 0.7);
    background-blend-mode: darken;
  }
  .row.marginTop20.bg-section > img, .row.marginTop20.bg-section > .content > img {
    margin: 0px !important;
  }
  .imageSection h1{
    font-size: 1.5rem;
    color: #fff;
  }
  .flow-root{
    padding: 0px;
    padding-left: 0px;
    display: grid;
    grid-template-columns: 1fr ;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    gap: 50px 50px;
    grid-template-areas:
        "box-1"
        "box-2"
        "box-3"
        "box-4"
        "box-5"
        "box-6";
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .slide h1 {
    top: 0;
    position: absolute;
    background: rgba(0,0,0,.5);
    color: #fff;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
  }
}

.selectOpt{
  width: 100%;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    background-color: #fff;
    font-family: inherit;
    font-size: inherit;
    transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 1rem;
    background-image: url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8dGl0bGU+YXJyb3ctYm90dG9tPC90aXRsZT4KPHBhdGggZD0iTTE1Ljk3NiAyMS4zODNjLTAuMDM1LTAuMDQ5LTAuMDYwLTAuMDk3LTAuMDk2LTAuMTMzLTQuMzgtNC4zODMtOC43Ni04Ljc2NS0xMy4xNDEtMTMuMTQ4LTAuNDA1LTAuNDA2LTAuOTE4LTAuNTE5LTEuMzk5LTAuMzEyLTAuNzcgMC4zMzItMC45ODUgMS4zMTktMC40MjQgMS45NDcgMC4wMjkgMC4wMzIgMC4wNjAgMC4wNjMgMC4wOTEgMC4wOTQgNC42OTMgNC42OTQgOS4zODQgOS4zODggMTQuMDc3IDE0LjA4MiAwLjQwMyAwLjQwMyAwLjk3MyAwLjUxMiAxLjQ2NCAwLjI2OCAwLjEyMy0wLjA2MSAwLjI0LTAuMTQ3IDAuMzM3LTAuMjQzIDQuNzA5LTQuNzA3IDkuNDE2LTkuNDE3IDE0LjEyMy0xNC4xMjcgMC4zNTEtMC4zNTEgMC40NzUtMC43NyAwLjMzNC0xLjI0Ni0wLjEzNi0wLjQ1OS0wLjQ1NS0wLjc0OS0wLjkyNS0wLjg0Ni0wLjQyNi0wLjA4OS0wLjgwNCAwLjAyOS0xLjExNCAwLjMzNy0wLjM5MyAwLjM5LTAuNzg0IDAuNzgzLTEuMTc1IDEuMTc1LTQuMDA0IDQuMDA2LTguMDA4IDguMDEzLTEyLjAxMiAxMi4wMTktMC4wMzYgMC4wMzYtMC4wNzQgMC4wNzEtMC4xMzggMC4xMzJ6Ij48L3BhdGg+CjxwYXRoIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzAwMCIgc3Ryb2tlLXdpZHRoPSIxLjAxMDUiIHN0cm9rZS1taXRlcmxpbWl0PSI0IiBzdHJva2UtbGluZWNhcD0iYnV0dCIgc3Ryb2tlLWxpbmVqb2luPSJtaXRlciIgZD0iTTE1Ljg4MSAyMS4yNWMtNC4zOC00LjM4My04Ljc2LTguNzY1LTEzLjE0MS0xMy4xNDgtMC40MDUtMC40MDYtMC45MTgtMC41MTktMS4zOTktMC4zMTItMC43NyAwLjMzMi0wLjk4NSAxLjMxOS0wLjQyNCAxLjk0NyAwLjAyOSAwLjAzMiAwLjA2MCAwLjA2MyAwLjA5MSAwLjA5NCA0LjY5MyA0LjY5NCA5LjM4NCA5LjM4OCAxNC4wNzcgMTQuMDgyIDAuNDAzIDAuNDAzIDAuOTczIDAuNTEyIDEuNDY0IDAuMjY4IDAuMTIzLTAuMDYxIDAuMjQtMC4xNDcgMC4zMzctMC4yNDMgNC43MDktNC43MDcgOS40MTYtOS40MTcgMTQuMTIzLTE0LjEyNyAwLjM1MS0wLjM1MSAwLjQ3NS0wLjc3IDAuMzM0LTEuMjQ2LTAuMTM2LTAuNDU5LTAuNDU1LTAuNzQ5LTAuOTI1LTAuODQ2LTAuNDI2LTAuMDg5LTAuODA0IDAuMDI5LTEuMTE0IDAuMzM3LTAuMzkzIDAuMzktMC43ODQgMC43ODMtMS4xNzUgMS4xNzUtNC4wMDQgNC4wMDYtOC4wMDggOC4wMTMtMTIuMDEyIDEyLjAxOS0wLjAzNiAwLjAzNi0wLjA3NCAwLjA3MS0wLjEzOCAwLjEzMi0wLjAzNS0wLjA0OS0wLjA2MC0wLjA5Ny0wLjA5Ni0wLjEzM3oiPjwvcGF0aD4KPC9zdmc+Cg==);
    background-size: 1.5rem;
    display: inline;
    cursor: pointer;
    background-position: right 2rem center;
    background-repeat: no-repeat;
}

.inputText{
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  background-color: #fff;
  font-family: inherit;
  font-size: inherit;
  transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1rem;
  display: inline;
  cursor: pointer;
}

.descargables a{
  margin-top: 1rem;
  text-align: center;
  color: white;
  font-family: "Open Sans","Helvetica Neue","Helvetica","Roboto","Arial",sans-serif;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  background: #007ec8;
  padding: 10px;
  border: 1px solid #fff;
}

.descargables a:hover{
  color: #007ec8;
  background: #fff;
  border-color: #007ec8;
}

.contenido_audiovisual .title{
  background: #007ec8;
  text-align: center;
  padding: 10px;
  font-size: 2rem;
  color: #fff;
}