
.brochure{
  background-color: #000;
  color: #fff;
}

.reverse{
  background-color: #007ec8;
  color: #fff;
}

.brochure h4, .reverse h4{
  font-size: 2rem;
}
.brochure h3, .reverse h3{
  font-size: 2rem;
  line-height: 3.6rem;
}

.reverse h3:before{
  background-color: #fff !important;
}

.brochure h3:before{
  background-color: #007ec8;
  content: "";
  margin-bottom: 4rem;
  display: block;
  width: 5rem;
  height: 0.2rem;
}

.brochure a:after {
  content: " ";
  position: absolute;
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0);
  margin-left: 0;
  z-index: 1;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  margin-left: 2.9rem;
  transition: margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.brochure a{
  margin-top: 7rem;
  position: relative;
  color: white;
  min-height: 2rem;
  display: inline-block;
  font-family: "Open Sans","Helvetica Neue","Helvetica","Roboto","Arial",sans-serif;
  font-weight: 600;
  cursor: pointer;
}

.reverse a{ color: #fff; }

.reverse a:after {background: #007ec8 !important;}
.reverse a:before {background: #fff !important;}
.brochure a:after {
  content: " ";
  position: absolute;
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255,255,255,0);
  margin-left: 0;
  z-index: 1;
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  margin-left: 2.9rem;
  transition: margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition: transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),margin 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86),-webkit-transform 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.brochure a:before {
  width: 2rem;
  background: #007ec8;
  z-index: 2;
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: all 500ms cubic-bezier(0.785, 0.135, 0.15, 0.86);
  content: " ";
  position: absolute;
  display: block;
  height: 1px;
  bottom: 0;
  left: 0;
}

.brochure a:hover:before{
  width: 100%;
}

.brochure a:hover:after{
  margin-left: 100%;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}
